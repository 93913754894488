<template>
  <article class="contenedor-hijo">
    <div class="container">
    <div class="row justify-content-center justify-content-md-between mb-3 mr-0 ml-2 ml-sm-0">
      <mdb-btn
        flat
        dark-waves
        icon="arrow-left"
        type="button"
        title="Regresar"
        @click="$router.go(-1)"
      >
        Regresar
      </mdb-btn>
    </div>
    <div
      v-if="cargando"
      class="mensaje-no-items py-3"
    >
      <LoaderKel />
      <p class="texto mt-2">
        Cargando
      </p>
    </div>
    <div v-else>
      <div class="encabezado-detalles">
        <header class="h4-responsive text-center pt-3">Detalles del cliente</header>
        <div class="row mx-0 mt-2">
          <div class="col-12 col-md-4">
            <span class="small-block font-titulo">Nombre o razón social</span>
            <p class="text-capitalize">{{empresa.nom_emp}}</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <span class="small-block font-titulo">RIF</span>
            <p class="text-capitalize">{{empresa.rif_emp}}</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <span class="small-block font-titulo">Relación</span>
            <p class="text-capitalize">{{empresa.nom_rel}}</p>
          </div>
        </div>
      </div>
      <h4 class="mt-2 d-inline">Sucursales</h4>
      <mdb-btn
        v-for="suc in sucursales"
        :key="'suc'+suc.id_suc"
        flat
        :class="['chip', {'primario activo': collapse === suc.id_suc}]"
        @click.native.prevent="[collapse === suc.id_suc ? collapse = 0 : collapse = suc.id_suc, idSucursalSeleccionada = suc.id_suc]"
      >
        {{suc.nom_suc}}
        <font-awesome-icon class="ml-1" v-if="(collapse === suc.id_suc)" icon="check-circle"/>
      </mdb-btn>
      <transition name="tabActiva-transicion">
        <div
          v-if="collapse"
          class="row mx-0 mb-4"
        >
          <div class="col-12 col-md-7 col-lg">
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_tcl && sucursales[idSucursalSeleccionada].nom_tcl.length">
              <span class="small-block font-titulo">
                Tipo de sucursal
              </span>
              {{sucursales[idSucursalSeleccionada].nom_tcl}}
            </p>
            <div class="d-flex justify-content-between">
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].sada_suc && sucursales[idSucursalSeleccionada].sada_suc.length">
                <span class="small-block font-titulo">
                  Sada
                </span>
                {{sucursales[idSucursalSeleccionada].sada_suc}}
              </p>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].prom_atraso" :class="[promedioDeAtraso(sucursales[idSucursalSeleccionada].prom_atraso).color]" >
                <span class="small-block font-titulo">
                  Promedio de atraso
                </span>
                {{ promedioDeAtraso(sucursales[idSucursalSeleccionada].prom_atraso).prom}}
              </p>
            </div>
            <div v-if="Object.keys(sucursales[idSucursalSeleccionada].restricciones).length">
              <small class="small-block font-titulo">
                Restricciones condición de pago
              </small>
              <div
                v-for="(restriccion, r) in sucursales[idSucursalSeleccionada].restricciones"
                :key="r"
                class="mb-2"
              >
                <span class="d-block">
                  {{restriccion.nom_cp}}
                </span>
                <span class="small d-block">
                  {{ restriccion.obs_rescp }}
                </span>
              </div>
            </div>
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].dir_suc && sucursales[idSucursalSeleccionada].dir_suc.length">
              <span class="small-block font-titulo">
                Dirección
              </span>
                {{sucursales[idSucursalSeleccionada].dir_suc}}
              <a
                v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].lon_suc && sucursales[idSucursalSeleccionada].lon_suc.length && sucursales[idSucursalSeleccionada].lat_suc && sucursales[idSucursalSeleccionada].lat_suc.length"
                :href="`https://www.google.com/maps/search/?api=1&query=${sucursales[idSucursalSeleccionada].lat_suc},${sucursales[idSucursalSeleccionada].lon_suc}`"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-flat ripple-parent my-0 py-1 px-3"
              >
                <font-awesome-icon icon="map-marked-alt" class="mr-1" />
                Ver mapa
              </a>
            </p>
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].est_zon && sucursales[idSucursalSeleccionada].ciu_zon && sucursales[idSucursalSeleccionada].sec_zon &&  sucursales[idSucursalSeleccionada].est_zon.length && sucursales[idSucursalSeleccionada].ciu_zon.length && sucursales[idSucursalSeleccionada].sec_zon.length">
              <span class="small-block font-titulo">
                Zona
              </span>
              {{sucursales[idSucursalSeleccionada].est_zon}}, {{sucursales[idSucursalSeleccionada].ciu_zon}}, {{sucursales[idSucursalSeleccionada].sec_zon}}
            </p>
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_res &&  sucursales[idSucursalSeleccionada].nom_res.length">
              <span class="small-block font-titulo">
                Agente de retención
              </span>
              {{sucursales[idSucursalSeleccionada].nom_res}}
            </p>
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].cod_suc && sucursales[idSucursalSeleccionada].cod_suc.length">
              <span class="small-block font-titulo">
                Código de la surcursal en sistema de facturación
              </span>
              {{sucursales[idSucursalSeleccionada].cod_suc}}
            </p>
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ema_suc && sucursales[idSucursalSeleccionada].ema_suc.length">
              <span class="small-block font-titulo">
                Email de la sucursal
              </span>
              {{sucursales[idSucursalSeleccionada].ema_suc}}
            </p>
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_per_ven && sucursales[idSucursalSeleccionada].nom_per_ven.length">
              <span class="small-block font-titulo">
                Vendedor
              </span>
              {{sucursales[idSucursalSeleccionada].nom_per_ven}} {{sucursales[idSucursalSeleccionada].ape_per_ven}}
            </p>
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_per_enc && sucursales[idSucursalSeleccionada].nom_per_enc.length">
              <span class="small-block font-titulo">
                Encargado
              </span>
              {{sucursales[idSucursalSeleccionada].nom_per_enc}} {{sucursales[idSucursalSeleccionada].ape_per_enc}}
            </p>
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ced_enc && sucursales[idSucursalSeleccionada].ced_enc.length">
              <span class="small-block font-titulo">
                Cédula del encargado
              </span>
              {{sucursales[idSucursalSeleccionada].ced_enc}}
            </p>
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ema_enc && sucursales[idSucursalSeleccionada].ema_enc.length">
              <span class="small-block font-titulo">
                Email del encargado
              </span>
              {{sucursales[idSucursalSeleccionada].ema_enc}}
            </p>
            <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].tel_suc && sucursales[idSucursalSeleccionada].tel_suc.length">
              <span class="small-block font-titulo">
                Teléfono de la sucursal
              </span>
              {{sucursales[idSucursalSeleccionada].tel_suc}}
              <mdb-btn
                flat
                dark-waves
                icon="phone"
                :class="['m-0 py-1 px-3', {'ml-2' : sucursales[idSucursalSeleccionada].tel_suc.length < 12}]"
                tag="a"
                :href="`tel:+${sucursales[idSucursalSeleccionada].tel_suc}`"
              />
              <mdb-btn
                flat
                dark-waves
                icon="envelope"
                class="m-0 py-1 px-3"
                tag="a"
                :href="`sms:+${sucursales[idSucursalSeleccionada].tel_suc}`"
              />
              <mdb-btn
                flat
                dark-waves
                fab icon="whatsapp"
                class="m-0 py-1 px-3"
                tag="a"
                :href="`https://wa.me/${sucursales[idSucursalSeleccionada].tel_suc}`"
              />
            </p>
            <p class="text-uppercase small font-titulo font-weight-bold mb-1">
              Horarios
            </p>
            <div v-if="Object.keys(sucursales[idSucursalSeleccionada].horarios).length">
              <div
                v-for="(dia, d) in sucursales[idSucursalSeleccionada].horarios"
                :key="d+idSucursalSeleccionada"
                class="mb-2"
              >
                <small class="small-block">
                  {{diasDeLaSemana[d]}}
                </small>
                <span
                  v-for="horario in dia"
                  :key="horario.id_hor"
                  class="d-block"
                >
                  {{ formatearHora(horario.hor_ini_hor) }} - {{ formatearHora(horario.hor_fin_hor) }}
                </span>
              </div>
            </div>
            <template v-else>
              <p class="text-caption">Sin horarios registrados</p>
            </template>
            <p class="text-uppercase small font-titulo font-weight-bold mb-1">
              Contactos adicionales
            </p>
            <div v-if="contactos[idSucursalSeleccionada] !== 'undefined'">
              <div
                v-for="con in contactos[idSucursalSeleccionada]"
                :key="'adi'+con.id_dat"
                class="mb-2"
              >
                <span class="small-block font-titulo">
                  {{con.obs_dat}}
                  <mdb-badge
                    v-if="con.tel_cat || false"
                    pill
                    color="unique-color-dark"
                    class="z-depth-0"
                  >
                    {{ con.tel_cat }}
                  </mdb-badge>
                </span>
                {{con.cam_dat}}
                <mdb-btn
                  flat
                  dark-waves
                  icon="phone"
                  class="m-0 py-1 px-3"
                  tag="a"
                  :href="`tel:+${con.cam_dat}`"
                />
                <mdb-btn
                  flat
                  dark-waves
                  icon="envelope"
                  class="m-0 py-1 px-3"
                  tag="a"
                  :href="`sms:+${con.cam_dat}`"
                />
                <mdb-btn
                  flat
                  dark-waves
                  fab icon="whatsapp"
                  class="m-0 py-1 px-3"
                  tag="a"
                  :href="`https://wa.me/${con.cam_dat}`"
                />
                <div class="row">
                  <mdb-btn
                    flat
                    dark-waves
                    title="Editar contacto"
                    class="py-2 m-0 "
                    icon="pen"
                    type="submit"
                    @click.native.prevent="añadir.estado = !añadir.estado; selecTel(con.cam_dat, con.obs_dat, con.id_dat, con.id_catcon)"
                  >
                    Editar
                  </mdb-btn>
                  <mdb-btn
                    flat
                    dark-waves
                    title="Eliminar contacto"
                    class="py-2 m-0"
                    icon="trash"
                    type="submit"
                    @click.native.prevent="modalConfirmacionEliminar = true; idCon = con.id_dat; conParaEliminar = `${con.obs_dat}`"
                  >
                    Eliminar
                  </mdb-btn>
                </div>
              </div>
            </div>
            <mdb-btn
              flat
              dark-waves
              class="m-0 py-2 px-1 px-sm-3"
              icon="plus"
              @click.native.prevent="añadir.estado = !añadir.estado; selecTel()"
            >
              Agregar teléfonos adicionales
            </mdb-btn>
            <div class="ocultar-overflow">
              <transition
                name="custom-classes-transition"
                enter-active-class="animated flipInX"
                leave-active-class="animated flipOutY"
                :duration="500"
                @after-enter="$refs.inputNuevoContacto.focus()"
              >
                <form
                  v-show="añadir.estado"
                  class="text-center my-4"
                  @submit.prevent="añadir.accion === 'agg' ? AggTelAdicionales(): EditarTelAdicionales()"
                >
                  <div class="row justify-content-center">
                    <div class="col-12">
                      <mdb-input
                        v-model.number="telefonoAgg"
                        ref="inputNuevoContacto"
                        label="Teléfono"
                        placeholder="Ej: 4127778888"
                        minlength="9"
                        maxlength="10"
                        type="tel"
                        invalidFeedback="Ej: 4142702277"
                        outline
                        :class="['my-2 text-left', validarValores.numero]"
                      />
                    </div>
                    <div class="col-12">
                      <div :class="['my-2 p-0 md-form md-outline outline-select',  validarValores.categoria]">
                        <select
                          id="telCat"
                          v-model="telCat"
                          class="custom-select"
                        >
                          <option class="d-none" disabled :value="{}">Seleccione</option>
                          <option
                            v-for="cat in catcon"
                            :key="cat.id_catcon"
                            :value="cat.id_catcon"
                          >
                            {{cat.nombre_catcon}}
                          </option>
                        </select>
                        <label
                          for="telCat"
                          :class="Object.values(telCat).length
                            ? 'label-active'
                            : 'label-inactive'"
                        >
                          Categoría
                        </label>
                      </div>
                    </div>
                    <div class="col-12">
                      <mdb-input
                        v-model.trim="personaConAgg"
                        label="Persona de contacto"
                        invalidFeedback="Ej: Espinoza"
                        :class="['my-2', validarValores.persona]"
                        outline
                        @keyup.enter="submit"
                      />
                    </div>
                    <div class="col-auto">
                      <mdb-btn
                        :title="añadir.accion === 'agg' ? 'Añadir nuevo contacto' : 'Actualizar contacto'"
                        color="secundario"
                        :icon="añadir.accion === 'agg' ? 'check' : 'sync'"
                        type="submit"
                      >
                        {{ añadir.accion === 'agg' ? 'Guardar' : 'Actualizar' }}
                      </mdb-btn>
                    </div>
                  </div>
                </form>
              </transition>
            </div>
          </div>
          <div class="'col-12 col-lg-7 col-xl-8 mt-lg-0 mt-4 px-lg-0">
            <ul
              v-if="pedidos[idSucursalSeleccionada]"
              class="table p-sucursal"
            >
              <li class="encabezado sticky">
                <h4 class="text-center m-2">Últimos pedidos</h4>
                <div class="thead claro">
                  <div class="col-sm-3 col-md-2">
                    Fecha
                    <span class="small-block">
                      del pedido
                    </span>
                  </div>
                  <div class="col-1">ID</div>
                  <div class="col-12 col-sm-3 text-right">
                    Estado
                    <span class="small-block">
                      Monto
                    </span>
                  </div>
                  <div class="col">Observaciones</div>
                </div>
              </li>
              <li
                v-for="(pedido, p) in pedidos[idSucursalSeleccionada]"
                :key="'ped'+pedido.id_ped+p"
                class="contenido cursor-pointer"
                @click="$router.push(`/pedido/${pedido.id_ped}`)"
              >
                <div
                  class="col-sm-3 col-md-2"
                  data-columna="Fecha del pedido"
                >
                  {{pedido.fec_ped}}
                </div>
                <div
                  class="col-1"
                  data-columna="ID"
                >
                  {{pedido.id_ped}}
                </div>
                <div
                  class="col-12 col-sm-3 text-sm-right"
                  data-columna="Estado / Monto"
                >
                  <p>
                    <span
                      :class="!pedido.est_ped
                        ? 'text-muted font-italic user-select-none'
                        : 'font-weight-bold ' + asignarColorEstado(pedido.est_ped)"
                    >
                      {{ pedido.est_ped.slice(0,1).toUpperCase() + pedido.est_ped.slice(1) }}
                    </span>
                    <span class="small-block">
                      {{pedido.mon_ped}}
                    </span>
                  </p>
                </div>
                <div
                  class="col text-break"
                  data-columna="Observaciones"
                >
                  <p :class="{'text-muted font-italic user-select-none':!pedido.obs_ped}">{{pedido.obs_ped || 'Sin observaciones'}}</p>
                </div>
              </li>
              <router-link
                :to="{name: 'Pedidos', params: {sucursal: idSucursalSeleccionada}}"
                v-slot="{navigate}"
                custom
              >
                <li class="contenido justify-content-center">
                  <mdb-btn
                    flat
                    dark-waves
                    icon="eye"
                    @click="navigate"
                  >
                    Ver más pedidos
                  </mdb-btn>
                </li>
              </router-link>
            </ul>
            <div
              v-else
              class="mensaje-no-items pb-3"
            >
              <h4 class="text-center m-2">Últimos pedidos</h4>
              <font-awesome-icon
                icon="clipboard"
                size="5x"
                class="icono"
              />
              <p class="texto">No hay pedidos</p>
            </div>
            <!-- Tabla de promedio últimos 5 pedidos -->
            <ul
              class="table p-sucursal mt-3"
              v-if="pedidosPro[idSucursalSeleccionada]"
            >
              <li class="encabezado sticky">
                <h4 class="text-center m-2">Promedio últimos 5 pedidos</h4>
                <div class="thead claro">
                  <div class="col">
                    Producto
                    <span class="small-block">Descripción - Categoría</span>
                  </div>
                  <div class="col-3 col-sm-4 text-right">
                    Promedio de cantidad
                  </div>
                </div>
              </li>
              <li
                v-for="(p, i) in pedidosPro[idSucursalSeleccionada]"
                :key="i"
                class="contenido hover-claro px-0"
              >
                <div
                  data-columna="Producto"
                  class="col-12 col-sm pr-1"
                >
                  <p>
                    {{ p.nom_pro }}
                    <span class="d-none d-sm-block small-block text-uppercase">
                      {{ p.des_pre }} - {{ p.nom_cat }}
                    </span>
                  </p>
                </div>
                <div
                  data-columna="Descripción"
                  class="col-12 d-sm-none"
                >
                  {{ p.des_pre }}
                </div>
                <div
                  data-columna="Categoría"
                  class="col-12 d-sm-none"
                >
                  {{ p.nom_cat }}
                </div>
                <div
                  class="col-12 col-sm-4 text-sm-right"
                  data-columna="Promedio de cantidad"
                >
                  {{ Number(p.pro_can).toFixed(2) }}
                </div>
              </li>
            </ul>
            <!-- Listado ultimas devoluciones -->
            <ul
              v-if="devoluciones[idSucursalSeleccionada]"
              class="table p-sucursal"
            >
              <li class="encabezado sticky">
                <h4 class="text-center m-2">Últimas devoluciones</h4>
                <div class="thead claro">
                  <div class="col-12 col-sm-3 col-md-2">Fecha</div>
                  <div class="col-12 col-sm-1">ID</div>
                  <div class="col-12 col-sm-3 text-right">Estado</div>
                  <div class="col">Observaciones</div>
                </div>
              </li>
              <li
                v-for="(devolucion, d) in devoluciones[idSucursalSeleccionada]"
                :key="'dev'+devolucion.id_dev+d"
                class="contenido cursor-pointer"
                @click="$router.push(`/devolucion/${d.id_dev}`)"
              >
                <div
                  class="col-12 col-sm-3 col-md-2"
                  data-columna="Fecha de la devolución"
                >
                  {{devolucion.fec_dev}}
                </div>
                <div
                  class="col-12 col-sm-1"
                  data-columna="ID"
                >
                  {{devolucion.id_dev}}
                </div>
                <div
                  class="col-12 col-sm-3"
                  data-columna="Estado"
                >
                  <p
                    :class="['text-sm-right', !devolucion.est_dev
                      ? 'text-muted font-italic user-select-none'
                      : 'font-weight-bold ' + asignarColorEstado(devolucion.est_dev)]"
                  >
                    {{devolucion.est_dev || 'Sin estatus definido'}}
                  </p>
                </div>
                <div
                  class="col text-break"
                  data-columna="Observaciones"
                >
                  <p :class="{'text-muted font-italic user-select-none':!devolucion.obs_dev}">{{devolucion.obs_dev || 'Sin observaciones'}}</p>
                </div>
              </li>
            </ul>
            <div
              v-else
              class="mensaje-no-items pb-3"
            >
              <h4 class="text-center m-2">Últimas devoluciones</h4>
              <font-awesome-icon
                icon="clipboard"
                size="5x"
                class="icono"
              />
              <p class="texto">No hay devoluciones</p>
            </div>
            <div v-if="facturas[idSucursalSeleccionada]">
              <ul class="table p-sucursal">
                <li class="encabezado sticky">
                  <h4 class="text-center m-2">Últimas facturas</h4>
                  <div class="thead claro">
                    <div class="col-sm col-md-3">
                      Fecha de emisión
                      <span class="small-block">
                        Fecha de vencimiento
                      </span>
                    </div>
                    <div class="col-12 col-sm-3">
                      Monto
                      <span class="small-block">
                        N° de factura
                      </span>
                    </div>
                    <div class="col-12 col-sm-2">
                      Fecha de pago
                      <span class="small-block">
                        Pagos
                      </span>
                    </div>
                    <div class="col-12 col-sm">
                      Estatus
                      <span class="small-block">
                        Observaciones
                      </span>
                    </div>
                  </div>
                </li>
                <li
                  v-for="(factura, p) in facturas[idSucursalSeleccionada]"
                  :key="'ped'+factura.id_fac+p"
                  class="contenido"
                >
                  <div
                    class="col-sm col-md-3"
                    data-columna="Fecha de emisión / Fecha de vencimiento"
                  >
                    <p>
                      {{ formatearFechaSinHora(factura.fec_cre_fac)}}
                      <span
                        v-if="factura.fec_ven_fac"
                        :class="[diasEstatusFac(factura.fec_ven_fac, factura.fec_pag_fac).color, 'd-block font-weight-bold']"
                      >
                        <p> {{formatearFechaSinHora(factura.fec_ven_fac)}} </p>
                        <p>
                          {{diasEstatusFac(factura.fec_ven_fac, factura.fec_pag_fac).dias}} días
                        </p>
                      </span>
                    </p>
                  </div>
                  <div
                    class="col-12 col-sm-3"
                    data-columna="Monto / N° de factura"
                  >
                    <p>
                      {{factura.mon_fac}}
                      <span class="small-block">
                        {{factura.num_fac}}
                      </span>
                    </p>
                  </div>
                  <div
                    class="col-12 col-sm-2"
                    data-columna="Fecha de pago / Pagos"
                  >
                    {{ formatearFechaSinHora(factura.fec_pag_fac) }}
                    <span class="small-block">
                      {{factura.pagos}}
                    </span>
                  </div>
                  <div
                    class="col text-break"
                    data-columna="Estatus / Observaciones"
                  >
                    <p>
                      <span
                        :class="['font-weight-bold', asignarColorEstado(factura.est_fac)]"
                      >
                        {{factura.est_fac}}
                      </span>
                      <small :class="['d-block', {'text-muted font-italic user-select-none': !factura.obs_fac }]">
                        {{factura.obs_fac ? factura.obs_fac  : 'Sin especificar'}}
                      </small>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="actividades[idSucursalSeleccionada]">
              <ul class="table p-sucursal">
                <li class="encabezado sticky">
                  <h4 class="text-center m-2">Últimas actividades</h4>
                  <div class="thead claro">
                    <div class="col-sm col-md-3">
                      Fecha de la actividad
                    </div>
                    <div class="col">
                      Acciones
                    </div>
                    <div class="col">Observaciones</div>
                  </div>
                </li>
                <li
                  v-for="(act, p) in actividades[idSucursalSeleccionada]"
                  :key="'act'+act.id_int+p"
                  class="contenido"
                >
                  <div
                    class="col-sm col-md-3"
                    data-columna="Fecha de la actividad"
                  >
                    <p>
                      {{ act.fecha_int }}
                    </p>
                  </div>
                  <div
                    class="col text-break"
                    data-columna="Acciones"
                  >
                    <p>
                      {{ act.acciones }}
                    </p>
                  </div>
                  <div
                    class="col text-break"
                    data-columna="Observaciones"
                  >
                    <span :class="{'text-muted font-italic user-select-none': !act.obs_int }">
                      {{act.obs_int ? act.obs_int  : 'Sin especificar'}}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          v-else-if="!collapse && Object.values(sucursales).length"
          class="mensaje-no-items pt-4 pb-5"
        >
          <font-awesome-icon
            icon="store"
            size="5x"
            class="icono"
          />
          <p class="texto text-center">
            Seleccione una sucursal para
            <span class="d-block">
              ver sus detalles
            </span>
          </p>
        </div>
      </transition>
    </div>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      :mensaje="`Esta acción eliminará el contacto de: ${conParaEliminar}`"
      @cerrar="modalConfirmacionEliminar = false, conParaEliminar = ''"
      @confirmar="EliminarTelAdicionales"
    />
    </div>
  </article>
</template>

<script>
import { mdbBtn, mdbInput, mdbBadge } from 'mdbvue'
import { apiPost } from '@/funciones/api.js'
import { validarTexto, capitalizarPalabras, asignarColorEstado, diasEstatusFac, formatearHora, formatearFechaSinHora } from '@/funciones/funciones.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'Cliente',
  components: {
    mdbBtn,
    mdbInput,
    mdbBadge,
    ConfirmacionEliminar,
    AlertaMensaje,
    LoaderKel
  },
  data () {
    return {
      telCat: '',
      catcon: [],
      asignarColorEstado,
      diasEstatusFac,
      formatearHora,
      formatearFechaSinHora,
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      idSucursalSeleccionada: 0,
      sucursales: {},
      empresa: {},
      pedidos: {},
      contactos: {},
      facturas: {},
      pedidosPro: {},
      actividades: {},
      devoluciones: {},
      collapse: 0,
      añadir: {
        estado: false,
        accion: ''
      },
      diasDeLaSemana: [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo'
      ],
      telefonoAgg: '',
      idEdiTel: '',
      personaConAgg: '',
      idCon: '',
      conParaEliminar: '',
      validarValores: {},
      cargando: false
    }
  },
  watch: {
    sucursales (valor) {
      const listaSucursales = Object.values(valor)
      if (listaSucursales.length === 1) {
        this.collapse = listaSucursales[0].id_suc
        this.idSucursalSeleccionada = listaSucursales[0].id_suc
      } else if (this.$route.params.idSuc) {
        this.collapse = this.$route.params.idSuc
        this.idSucursalSeleccionada = this.$route.params.idSuc
      }
    }
  },
  methods: {
    cargaInicial () {
      this.cargando = true
      this.resultados = {}
      apiPost({ s: 'empresa' }, 'cliente_id=' + this.$route.params.id)
        .then((res) => {
          this.enlistarSucursales(res.data.suc, res.data.promAtraso, res.data.hor, res.data.restricciones)
          this.enlistarPedidos(res.data.ped)
          this.enlistarDevoluciones(res.data.dev)
          this.enlistarFacturas(res.data.facturas)
          this.enlistarPedidosPro(res.data.prePedidas)
          this.enlistarActividades(res.data.act)
          this.enlistarContactos(res.data.con)
          this.catcon = res.data.catcon
          this.empresa = res.data.emp
        })
    },
    enlistarSucursales (data, atraso, horarios, restricciones) {
      const sucursal = {}
      data.forEach(s => {
        if (!sucursal[s.id_suc]) {
          sucursal[s.id_suc] = {
            id_suc: s.id_suc,
            nom_suc: s.nom_suc,
            sada_suc: s.sada_suc,
            nom_res: s.nom_res,
            cod_suc: s.cod_suc,
            tel_suc: s.tel_suc,
            ema_suc: s.ema_suc,
            dir_suc: s.dir_suc,
            est_zon: s.est_zon,
            ciu_zon: s.ciu_zon,
            sec_zon: s.sec_zon,
            nom_per_ven: s.nom_per_ven,
            sco_suc: s.sco_suc,
            nom_per_enc: s.nom_per_enc,
            ape_per_enc: s.ape_per_enc,
            lat_suc: s.lat_suc,
            lon_suc: s.lon_suc,
            email_dat: {},
            ema_enc: s.ema_enc,
            ced_enc: s.ced_enc,
            ape_per_ven: s.ape_per_ven,
            nom_tcl: s.nom_tcl,
            prom_atraso: 0,
            horarios: {},
            restricciones: []
          }
        }
      })
      horarios.forEach((h) => {
        if (sucursal[h.id_suc_hor]) {
          if (!sucursal[h.id_suc_hor].horarios[h.num_dia_hor]) {
            sucursal[h.id_suc_hor].horarios[h.num_dia_hor] = []
          }
          sucursal[h.id_suc_hor].horarios[h.num_dia_hor].push(h)
        }
      })
      restricciones.forEach((r) => {
        if (sucursal[r.id_suc_rescp]) sucursal[r.id_suc_rescp].restricciones.push(r)
      })
      atraso.forEach(a => {
        if (sucursal[a.id_suc_fac]) sucursal[a.id_suc_fac].prom_atraso = a.promedio_dias_atraso
      })
      this.sucursales = sucursal
      this.cargando = false
    },
    enlistarPedidos (data) {
      const pedidos = {}
      data.forEach(p => {
        if (!pedidos[p.id_suc_ped]) {
          pedidos[p.id_suc_ped] = []
        }
        pedidos[p.id_suc_ped].push({
          est_ped: p.est_ped,
          fec_ped: p.fec_ped,
          id_ped: p.id_ped,
          mon_ped: p.mon_ped,
          obs_ped: p.obs_ped
        })
      })
      this.pedidos = pedidos
    },
    enlistarFacturas (data) {
      const facturas = {}
      data.forEach(p => {
        if (!facturas[p.id_suc_fac]) {
          facturas[p.id_suc_fac] = []
        }
        facturas[p.id_suc_fac].push({
          est_fac: p.est_fac,
          fec_cre_fac: p.fec_cre_fac,
          fec_ven_fac: p.fec_ven_fac,
          fec_pag_fac: p.fec_pag_fac,
          id_fac: p.id_fac,
          num_fac: p.num_fac,
          mon_fac: p.mon_fac,
          obs_fac: p.obs_fac,
          pagos: p.pagos,
          edit_fac: false
        })
      })
      this.facturas = facturas
    },
    enlistarPedidosPro (data) {
      const pedidos = {}
      data.forEach(p => {
        if (!pedidos[p.id_suc_ped]) {
          pedidos[p.id_suc_ped] = []
        }
        pedidos[p.id_suc_ped].push({
          nom_pro: p.nom_pro,
          des_pre: p.des_pre,
          nom_cat: p.nom_cat,
          pro_can: p.promedio_cantidad
        })
      })
      this.pedidosPro = pedidos
    },
    enlistarActividades (data) {
      const actividades = {}
      data.forEach(p => {
        if (!actividades[p.id_suc]) {
          actividades[p.id_suc] = []
        }
        actividades[p.id_suc].push({
          fecha_int: p.fecha_int,
          acciones: p.acciones,
          obs_int: p.obs_int,
          id_int: p.id_int
        })
      })
      this.actividades = actividades
    },
    enlistarDevoluciones (data) {
      const devoluciones = {}
      data.forEach(p => {
        if (!devoluciones[p.id_suc_dev]) {
          devoluciones[p.id_suc_dev] = []
        }
        devoluciones[p.id_suc_dev].push({
          est_dev: p.est_dev,
          fec_dev: p.fec_dev,
          id_dev: p.id_dev,
          id_suc_dev: p.id_suc_dev,
          obs_dev: p.obs_dev
        })
      })
      this.devoluciones = devoluciones
    },
    enlistarContactos (data) {
      const contacto = {}
      data.forEach((c) => {
        if (!contacto[c.id_suc]) {
          contacto[c.id_suc] = []
        }
        contacto[c.id_suc].push({
          id_dat: c.id_dat,
          cam_dat: c.cam_dat,
          obs_dat: c.obs_dat,
          tel_cat: c.nombre_catcon,
          id_catcon: c.id_catcon
        })
      })
      this.contactos = contacto
    },
    promedioDeAtraso (promedio) {
      if (Number(promedio) < 0) {
        return {
          prom: ((Number(promedio)).toFixed(2) * -1) + ' días adelantado',
          color: 'texto-exitoso'
        }
      } else if (Number(promedio) > 0) {
        return {
          prom: (Number(promedio)).toFixed(2) + ' días atrasado',
          color: 'texto-error'
        }
      } else {
        return {
          prom: (Number(promedio)).toFixed(2) + ' días atrasado',
          color: 'texto-exitoso'
        }
      }
    },
    EliminarTelAdicionales () {
      this.añadir.estado = false
      apiPost({ s: 'eliAdsM' }, 'dat=' + this.idCon)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.cargaInicial()
            this.idCon = ''
            this.modalConfirmacionEliminar = false
          }
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un problema eliminando el contacto',
            tipo: 'error'
          }
        })
    },
    formatearTelefono (telefono) {
      return telefono.slice(2)
    },
    EditarTelAdicionales () {
      const existeTelefonoDuplicado = this.validarTelefonoDuplicado()
      this.validarValores = {
        persona: !this.personaConAgg || this.personaConAgg === '' || !validarTexto(this.personaConAgg) ? 'invalido' : 'valido',
        numero: this.telefonoAgg.toString().length !== 10 || existeTelefonoDuplicado ? 'invalido' : 'valido',
        categoria: this.telCat.length ? 'valido-select' : 'invalido-select'
      }
      if (existeTelefonoDuplicado) {
        this.alertaMensaje = {
          contenido: 'El telefono que intenta agregar ya se encuentra registrado en la sucursal',
          tipo: 'advertencia'
        }
        return
      }
      this.personaConAgg = capitalizarPalabras(this.personaConAgg)
      if (this.telefonoAgg.toString().length !== 10) {
        this.alertaMensaje = {
          contenido: 'El teléfono ingresado no cumple con el formato. Ejemplo del formato: 584147894562',
          tipo: 'error'
        }
        return
      }
      if (!this.personaConAgg || this.personaConAgg === '') {
        this.alertaMensaje = {
          contenido: 'La persona de contacto es requerida',
          tipo: 'error'
        }
        return
      }
      if (!validarTexto(this.personaConAgg)) {
        this.alertaMensaje = {
          contenido: 'La persona de contacto debe contener solo texto',
          tipo: 'error'
        }
        return
      }
      const telefonoFormateado = '58' + this.telefonoAgg
      apiPost({ s: 'modAdsM' }, 'telad=' + telefonoFormateado + '&nomad=' + this.personaConAgg + '&dat=' + this.idEdiTel + '&catcon=' + this.telCat)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.cargaInicial()
            this.telefonoAgg = ''
            this.personaConAgg = ''
            this.telCat = ''
            this.validarValores = {}
            this.añadir.estado = false
          }
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un problema actualizando el contacto',
            tipo: 'error'
          }
        })
    },
    validarTelefonoDuplicado () {
      if (Object.keys(this.contactos).includes(this.idSucursalSeleccionada) && this.contactos[this.idSucursalSeleccionada].length) {
        const contactos = this.contactos[this.idSucursalSeleccionada].filter(({ cam_dat: telefonoRegistrado }) => telefonoRegistrado === '58' + this.telefonoAgg.toString())
        return contactos.length > 0 && contactos[0].id_dat !== this.idEdiTel
      }
    },
    AggTelAdicionales () {
      const existeTelefonoDuplicado = this.validarTelefonoDuplicado()
      this.validarValores = {
        persona: !this.personaConAgg || this.personaConAgg === '' || !validarTexto(this.personaConAgg) ? 'invalido' : 'valido',
        numero: this.telefonoAgg.toString().length !== 10 || existeTelefonoDuplicado ? 'invalido' : 'valido',
        categoria: this.telCat.length ? 'valido-select' : 'invalido-select'
      }
      if (existeTelefonoDuplicado) {
        this.alertaMensaje = {
          contenido: 'El telefono que intenta agregar ya se encuentra registrado en la sucursal',
          tipo: 'advertencia'
        }
        return
      }
      this.personaConAgg = capitalizarPalabras(this.personaConAgg)
      if (this.telefonoAgg.toString().length !== 10) {
        this.alertaMensaje = {
          contenido: 'El teléfono ingresado no cumple con el formato. Ejemplo del formato: 584147894562',
          tipo: 'error'
        }
        return
      }
      if (!this.personaConAgg || this.personaConAgg === '') {
        this.alertaMensaje = {
          contenido: 'La persona de contacto es requerida',
          tipo: 'error'
        }
        return
      }
      if (!validarTexto(this.personaConAgg)) {
        this.alertaMensaje = {
          contenido: 'La persona de contacto debe contener solo texto',
          tipo: 'error'
        }
        return
      }
      const telefonoFormateado = '58' + this.telefonoAgg
      apiPost({ s: 'aggAdsM' }, 'telad=' + telefonoFormateado + '&nomad=' + this.personaConAgg + '&l=' + this.idSucursalSeleccionada + '&sq=' + 'suc' + '&catcon=' + this.telCat)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.cargaInicial()
            this.telefonoAgg = ''
            this.personaConAgg = ''
            this.telCat = ''
            this.validarValores = {}
            this.añadir.estado = false
          }
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un problema agregando el contacto',
            tipo: 'error'
          }
        })
    },
    selecTel (conTel, conNom, conId, conCat) {
      this.validarValores = {}
      if (conTel) {
        this.telefonoAgg = this.formatearTelefono(conTel)
        this.personaConAgg = conNom
        this.telCat = conCat
        this.añadir.accion = 'edi'
        this.idEdiTel = conId
      } else {
        this.añadir.accion = 'agg'
        this.telefonoAgg = ''
        this.personaConAgg = ''
      }
    }
  },
  mounted () {
    this.cargaInicial()
  }
}
</script>

<style lang="scss" scoped>
.table.p-sucursal {
  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "576px";
  @include ul-table-responsive-at ($breakpointToChange);
}
.ocultar-overflow {
  overflow: hidden;
}
</style>
