import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars, faBoxes, faBox, faCheckCircle, faColumns, faEllipsisV, faExclamationCircle, faInfoCircle,
  faList, faMapMarkedAlt, faSignOutAlt, faTimes, faTimesCircle, faAngleRight, faCheck,
  faUsers, faPrint, faFilePdf, faCalendarTimes, faChartBar, faClipboardList, faUserPlus,
  faClipboard, faMapMarkerAlt, faSearch, faFileInvoice, faStore, faChevronDown, faBan,
  faGripLines, faGripLinesVertical, faUserTie, faTruck, faDolly, faCalendar, faUser, faBullseye,
  faPlus, faMinus

} from '@fortawesome/free-solid-svg-icons'
import { /* Regulares */ } from '@fortawesome/free-regular-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

library.add(
  // Solid
  faBars, faBoxes, faBox, faCheckCircle, faColumns, faEllipsisV, faExclamationCircle, faInfoCircle,
  faList, faMapMarkedAlt, faSignOutAlt, faTimes, faTimesCircle, faAngleRight, faCheck,
  faUsers, faPrint, faFilePdf, faCalendarTimes, faChartBar, faClipboardList, faUserPlus,
  faClipboard, faMapMarkerAlt, faSearch, faFileInvoice, faStore, faChevronDown, faBan,
  faGripLines, faGripLinesVertical, faUserTie, faTruck, faDolly, faCalendar, faUser, faBullseye,
  faPlus, faMinus,
  // Regular
  // Brand
  faWhatsapp
)
