<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <div class="vista-encabezado">
        <header class="col titulo h4-responsive text-center text-md-left">
          Filtros de búsqueda
        </header>
        <mdb-btn
          color="primario"
          icon="bullseye"
          @click="modalMeta.mostrar = true"
        >
          Nueva planificación
        </mdb-btn>
      </div>
      <div class="col-12 col-md-4 col-xl-3">
        <mdb-input
          v-model.number="fechaAFiltrar"
          type="number"
          :min="1900"
          :max="2099"
          class="my-1 my-sm-2"
          label="Año"
          outline
        />
      </div>
      <!-- Botón para cambiar vendedor a cliente/s  -->
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <ul
        v-else-if="Object.keys(planificaciones).length"
        class="table t-metas"
      >
        <li class="encabezado sticky">
          <div class="row align-items-center mx-0">
            <div class="col-12 col-md px-0 text-center text-sm-left">
              <header class="h4-responsive">Planificaciones</header>
              <!-- <p class="descripcion text-capitalize">En lista: {{Object.keys(clientes).length}} empresas, {{totalSucursales}} sucursales</p> -->
            </div>
          </div>
          <div class="thead claro">
            <div class="col">Fecha</div>
            <div class="col">Bultos</div>
            <div class="col text-right">Peso</div>
          </div>
        </li>
        <router-link
          :to="{name: 'Meta', params: {id: pla.id_metas}}"
          custom
          v-slot="{navigate}"
          v-for="pla in planificaciones"
          :key="pla.id_metas"
          class="contenido cursor-pointer"
        >
          <li @click="navigate">
            <div class="col" data-columna="Fecha">
              <p class="text-capitalize">
                {{  pla.fec_metas }}
              </p>
            </div>
            <div class="col" data-columna="Bultos">
              <p>
                {{  pla.can_total_metas }}
              </p>
            </div>
            <div class="col" data-columna="Peso">
              <p class="text-right">
                {{ pla.peso_total_metas }} kg
              </p>
            </div>
          </li>
        </router-link>
      </ul>
      <div
        v-else-if="!Object.keys(planificaciones).length && filtrosActivos"
        class="mensaje-no-items py-5"
      >
        <span class="fa-stack icono position-relative ml-n4 mb-4">
          <font-awesome-icon
            icon="search"
            size="5x"
            class="fa-stack-2x"
          />
          <font-awesome-icon
            icon="times"
            size="2x"
            class="fa-stack-1x"
            style="position: absolute;top: -10px;left: 14px"
          />
        </span>
        <p class="texto text-center">
          No se encontraron planificaciones
          <span class="d-block">durante la búsqueda</span>
        </p>
      </div>
      <div
        v-else
        class="mensaje-no-items py-5"
      >
        <font-awesome-icon
          icon="search"
          size="5x"
          class="icono"
        />
        <p class="texto text-center">
          Consulta la fecha en
          <span class="d-block">el filtro para continuar</span>
        </p>
      </div>
    </article>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view
        @editar="
          modalMeta = {
            mostrar: true,
            esEditar: true
          },
          enlistarPreEditar($event)
        "
      />
    </transition>
    <form @submit.prevent="guardarPlanificacion()">
      <mdb-modal
        centered
        elegant
        scrollable
        :show="modalMeta.mostrar"
        @close="modalMeta.mostrar = false"
      >
        <mdb-modal-header>
          <mdb-btn
            v-if="existenItemsRellenados"
            flat
            dark-waves
            class="m-0 py-2 px-3"
            @click="expandirItemsRellenados()"
          >
            {{ mostrarProductosExpandidos ? 'Ver productos' : 'Ocultar productos'  }}
          </mdb-btn>
          <mdb-modal-title>
            Nueva planificación
          </mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body class="overflow-scroll h-75 pb-0">
          <div>
            <mdb-input
              v-model="fechaPlan"
              type="date"
              label="Fecha de planificación"
              class="my-1"
              outline
            />
          </div>
          <div
            v-for="(pre, i) in presentaciones"
            :key="'pre' + i"
          >
            <!-- Nombre de la categoría -->
            <header class="h5-responsive text-center">
              {{pre.nom_cat}}
            </header>
            <!-- Productos -->
            <div
              v-for="(producto, I) in pre.productos"
              :key="'producto'+I"
              :class="['producto', {'activo': indexCartasExpandidas.includes(I)}]"
            >
              <div
                class="encabezado"
                @click.stop.prevent="abrirCarta(I)"
              >
                <header>
                  {{producto.nom_pro}}
                </header>
                <font-awesome-icon
                  :icon="indexCartasExpandidas.includes(I) ? 'minus' : 'plus'"
                  :class="['icono', {'fa-rotate-180': indexCartasExpandidas.includes(I)}]"
                />
              </div>
              <!-- Presentaciones -->
              <div v-if="indexCartasExpandidas.includes(I)">
                <div
                  v-for="(presentacion, Ip) in producto.presentaciones"
                  :key="'presentacion'+Ip"
                  class="detalles-presentacion"

                >
                  <template>
                    <div class="descripcion col px-0">
                      <p class="text-uppercase">
                        {{presentacion.des_pre}}
                        <small class="d-block">
                          <span class="texto-primario mr-2">Bs. {{presentacion.pvmp_pre}}</span>
                          {{presentacion.fec_mod_pre_pre.substring(0,10)}}
                        </small>
                      </p>
                    </div>
                    <div class="px-0 d-flex col-5 col-sm-4">
                      <div class="p-0 pr-2 text-right">
                        {{presentacion.uni_paq_pre}}
                        <small class="d-block text-uppercase text-muted">
                          {{presentacion.paq_pre}}
                        </small>
                      </div>
                      <div class="col px-0 pr-2">
                        <mdb-input
                          v-model.number="presentacion.cantidad"
                          type="number"
                          label="Cantidad"
                          :min="0"
                          @input="presentacion.cantidad = Math.abs(Number($event)); asignarPropiedad(presentacion, presentacion.check)"
                          size="sm"
                          placeholder="Cantidad"
                          class="my-2 text-right"
                          outline
                        />
                        <small class="form-text text-muted text-right mt-n2">
                          <!-- En caso es pedido se aprecia un monto total del pedido (presentación) -->
                          {{presentacion.cantidad ? (presentacion.pes_pre * presentacion.cantidad) : '0'}} KG
                        </small>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <mdb-modal-footer class="py-0 px-2 modal-footer">
            <mdb-btn
              color="primario"
              class="col"
              type="submit"
            >
              Guardar
            </mdb-btn>
          </mdb-modal-footer>
        </mdb-modal-body>
      </mdb-modal>
    </form>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
// @ is an alias to /src
import {
  mdbBtn,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalFooter,
  mdbModalTitle,
  mdbModalBody
} from 'mdbvue'
import { apiPost } from '@/funciones/api.js'
import { comprobarRutaHija } from '@/funciones/funciones.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'Metas',
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalFooter,
    mdbModalTitle,
    mdbModalBody,
    AlertaMensaje,
    LoaderKel
  },
  data () {
    return {
      fechaAFiltrar: 2025,
      totales: {
        cantidad: 0,
        peso: 0
      },
      planificaciones: [],
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      cargando: false,
      timeout: null,
      // Modal de planificación
      pedido: {},
      presentaciones: [],
      fechaPlan: '',
      mostrarProductosExpandidos: false,
      indexCartasExpandidas: [],
      modalMeta: {
        mostrar: false,
        esEditar: false
      }
    }
  },
  methods: {
    cargaInicial () {
      this.resultados = {}
      apiPost({ s: 'presentacionesMv' }, '')
        .then((res) => {
          if (res.data.pre) {
            this.enlistarPresentaciones(res.data.pre)
          }
        })
    },
    aplicarFiltros () {
      if (this.timeout) { clearTimeout(this.timeout) }
      this.timeout = setTimeout(() => {
        this.cargando = true
        apiPost({ s: 'metasMv' }, 'fec=' + this.fechaAFiltrar)
          .then((res) => {
            this.planificaciones = res.data.metas
            this.cargando = false
          })
      }, 500) // delay
    },
    expandirItemsRellenados () {
      if (!this.mostrarProductosExpandidos) {
        this.indexCartasExpandidas = []
      } else {
        Object.values(this.presentaciones).forEach((cat) => {
          Object.values(cat.productos).forEach((pro) => {
            Object.values(pro.presentaciones).forEach((pre) => {
              if (pre.cantidad > 0) {
                this.abrirCarta(pro.nom_pro)
              }
            })
          })
        })
      }
      this.mostrarProductosExpandidos = !this.mostrarProductosExpandidos
    },
    abrirCarta (index) {
      if (this.indexCartasExpandidas.includes(index)) {
        this.indexCartasExpandidas = this.indexCartasExpandidas.filter((i) => i !== (index))
      } else {
        this.indexCartasExpandidas.push(index)
      }
    },
    enlistarPreEditar (meta) {
      Object.values(this.presentaciones).forEach((cat) => {
        Object.values(cat.productos).forEach((pro) => {
          Object.values(pro.presentaciones).forEach((pre) => {
            meta.forEach((met) => {
              console.log(met)
              if (met.nom_pro === pro.nom_pro && met.des_pre === pre.des_pre) {
                this.presentaciones[cat.nom_cat].productos[pro.nom_pro].presentaciones[pre.des_pre].cantidad = met.can_met_pre
              }
            })
          })
        })
      })
    },
    enlistarPresentaciones (data) {
      const presentaciones = {}
      data.forEach((e) => {
        if (!presentaciones[e.nom_cat]) {
          presentaciones[e.nom_cat] = {
            nom_cat: e.nom_cat,
            productos: {}
          }
        }
        if (!presentaciones[e.nom_cat].productos[e.nom_pro]) {
          presentaciones[e.nom_cat].productos[e.nom_pro] = {
            nom_pro: e.nom_pro,
            presentaciones: {}
          }
        }
        console.log(presentaciones)
        if (!presentaciones[e.nom_cat].productos[e.nom_pro].presentaciones[e.des_pre]) {
          presentaciones[e.nom_cat].productos[e.nom_pro].presentaciones[e.des_pre] = {
            id_pre: e.id_pre,
            des_pre: e.des_pre,
            pvmp_pre: e.pvmp_pre,
            fec_mod_pre_pre: e.fec_mod_pre_pre,
            uni_paq_pre: e.uni_paq_pre,
            paq_pre: e.paq_pre,
            hab_pre: e.hab_pre,
            pes_pre: e.pes_pre,
            // Otros campos importantes de la presentacion
            cantidad: e.cantidad,
            unidad: e.unidad,
            check: e.check,
            observacion: e.observacion
          }
        }
        // presentaciones[e.id_emp].tipos[e.id_tcl_suc] = e.id_tcl_suc
        // presentaciones[e.id_emp].zonas[e.id_zon_suc] = e.id_zon_suc
      })
      this.presentaciones = presentaciones
    },
    asignarPropiedad (presentacion) {
      if (!presentacion.cantidad) {
        if (!this.modalMeta.esEditar) {
          this.alertaMensaje = {
            contenido: 'La cantidad del pedido no puede ser 0',
            tipo: 'advertencia'
          }
          return
        } else {
          // En caso se edite un pedido y se desee eliminar la presentación
          return delete this.pedido[presentacion.id_pre]
        }
      }
      this.pedido[presentacion.id_pre] = {
        cantidad: presentacion.cantidad,
        peso: presentacion.pes_pre
      }
    },
    guardarPlanificacion () {
      if (!this.fechaPlan) {
        this.alertaMensaje = {
          contenido: 'La fecha de planificación es requerida',
          tipo: 'advertencia'
        }
        return
      }
      if (!Object.keys(this.pedido).length) {
        this.alertaMensaje = {
          contenido: 'No se ha seleccionado ningún producto',
          tipo: 'advertencia'
        }
        return
      }
      this.botonDeshabilitado = true
      this.totales = {
        cantidad: 0,
        peso: 0
      }
      for (const p in this.pedido) {
        if (this.pedido[p]) {
          this.totales = {
            cantidad: this.totales.cantidad + Number(this.pedido[p].cantidad),
            peso: this.totales.peso + (Number(this.pedido[p].peso) * Number(this.pedido[p].cantidad))
          }
        }
      }
      const data = {
        fec: this.fechaPlan,
        canTotal: this.totales.cantidad,
        pesTotal: this.totales.peso,
        pre: this.pedido
      }
      apiPost({ s: 'crearMetasMv' }, JSON.stringify(data))
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Planificación guardada exitosamente',
              tipo: 'exito'
            }
            this.modalMeta.mostrar = false
            this.cargaInicial()
          } else {
            this.alertaMensaje = {
              contenido: res.data.e,
              tipo: 'error'
            }
          }
          this.botonDeshabilitado = false
        })
    }
  },
  computed: {
    filtrosActivos () {
      return (this.fechaAFiltrar.length)
    },
    siMuestraRutaHija () {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'))
    },
    existenItemsRellenados () {
      return !!Object.values(this.presentaciones).find((cat) => {
        return Object.values(cat.productos).find((pro) => {
          return Object.values(pro.presentaciones).find((pre) =>
            pre.cantidad > 0
          )
        })
      })
    }
  },
  watch: {
    fechaAFiltrar: function () {
      this.aplicarFiltros()
    }
  },
  mounted () {
    this.cargaInicial()
    this.aplicarFiltros()
  }
}
</script>

<style lang="scss" scoped>
.table.t-metas {
  margin: 2.5rem auto 6rem;

  .contenido > [class*=col] {line-height: 1.3}

  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "768px";
  @include ul-table-responsive-at ($breakpointToChange);

  // Anchos específicos de columnas
  @media screen and (min-width: $breakpointToChange) {
    .acciones {width: 140px};
  }
}
.custom-switch {
  margin: -.75rem 1rem 0 0;
  .texto {
    bottom: -39px;
    color: $gris-muted;
    font-size: 10px;
    left: -44px;
    position: absolute;
  }
}
.producto {
  margin: .5rem 0;

  &:last-child {
    margin-bottom: 1rem;
  }

  .encabezado {
    align-items: center;
    background-color: lighten($secundario, 48%);
    border: 1px solid lighten($secundario,40%);
    border-style: none none solid;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0 -1rem;
    padding: .5rem 1rem;
    transition: background-color .25s ease;

    header {
      @include agregar-prefijos(user-select, none)
    }

    .icono {
      margin-right: .5rem;
      transition: transform .25s ease;
    }
  }

  // Cuando se despliega los detalles del producto
  &.activo .encabezado {
    background-color: lighten($secundario, 32%);
  }
}
.detalles-presentacion {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .descripcion {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;

    div {
      width: auto;
    }
    p {
      margin-bottom: 0;
      flex-grow: 2;
    }
  }
}
.modal-footer {
  background-color: #fff;
  position: sticky;
  bottom: 0;
}
</style>
