<template>
  <article class="contenedor-hijo">
    <div class="container">
    <div class="row justify-content-center justify-content-md-between mb-3 mr-0 ml-2 ml-sm-0">
      <mdb-btn
        flat
        dark-waves
        icon="arrow-left"
        type="button"
        title="Regresar"
        @click="$router.go(-1)"
      >
        Regresar
      </mdb-btn>
      <!-- <mdb-btn
        flat
        dark-waves
        icon="trash"
        @click="modalConfirmacionEliminar = true"
      >
        Eliminar
      </mdb-btn>
      <mdb-btn
        flat
        dark-waves
        icon="pen"
        @click="$emit('editar', meta)"
      >
        Editar
      </mdb-btn> -->
    </div>
    <div
      v-if="cargando"
      class="mensaje-no-items py-3"
    >
      <LoaderKel />
      <p class="texto mt-2">
        Cargando
      </p>
    </div>
    <div v-else>
      <div class="encabezado-detalles">
        <header class="h4-responsive text-center pt-3">Detalles del la meta</header>
        <div class="row mx-0 mt-2">
          <div class="col-12 col-md-4">
            <span class="small-block font-titulo">Fecha</span>
            <p class="text-capitalize">{{meta[0]?.fec_metas}}</p>
          </div>
          <div class="col-12 col-md-4">
            <span class="small-block font-titulo">Cantidad</span>
            <p class="text-capitalize">{{meta[0]?.can_total_metas}}</p>
          </div>
          <div class="col-12 col-md-4">
            <span class="small-block font-titulo">Peso</span>
            <p class="text-capitalize">{{meta[0]?.peso_total_metas}} kg</p>
          </div>
        </div>
      </div>
      <ul
        v-if="Object.keys(meta).length"
        class="table t-meta"
      >
        <li class="encabezado sticky">
          <div class="row align-items-center mx-0">
            <div class="col-12 col-md px-0 text-center text-sm-left">
              <header class="h4-responsive">Metas</header>
              <p class="descripcion text-capitalize">En lista: {{Object.keys(meta).length}}</p>
            </div>
          </div>
          <div class="thead claro">
            <div class="col">
              Nombre
              <span class="small-block">
                Categoría
              </span>
            </div>
            <div class="col">
              <p class="text-right">
                Propuesta
                <span class="small-block">
                  Inventario
                </span>
              </p>
            </div>
            <div class="col">
              <p class="text-right">
                Bultos terminados
                <span class="small-block">
                  Peso
                </span>
              </p>
            </div>
          </div>
        </li>
        <li
          v-for="me in meta"
          :key="me.id_meta"
          class="contenido"
        >
          <div class="col" data-columna="Fecha">
            <p class="text-capitalize">
              {{  me.nom_pro }} {{  me.des_pre }}
              <span class="small-block">
                {{  me.nom_cat }}
              </span>
            </p>
          </div>
          <div class="col text-md-right" data-columna="Bultos / Inventario">
            <p>
              {{  me.can_met_pre }}
              <span class="small-block">
                {{  me.can_pre }}
              </span>
            </p>
          </div>
          <div class="col" data-columna="Bultos terminados / Peso">
            <p class="text-md-right">
              {{ me.can_pre - me.can_met_pre }}
              <span class="small-block">
                {{ ((me.can_pre - me.can_met_pre) * me.pes_pre).toFixed(2) }} kg
              </span>
            </p>
          </div>
        </li>
      </ul>
    </div>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      mensaje="Esta acción eliminará la planificación"
      @cerrar="modalConfirmacionEliminar = false, plaParaEliminar = ''"
      @confirmar="eliminarPlanificacion()"
    />
    </div>
  </article>
</template>

<script>
import { mdbBtn } from 'mdbvue'
import { apiPost } from '@/funciones/api.js'
// import { validarTexto, capitalizarPalabras, asignarColorEstado, diasEstatusFac, formatearHora, formatearFechaSinHora } from '@/funciones/funciones.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'Meta',
  components: {
    mdbBtn,
    ConfirmacionEliminar,
    AlertaMensaje,
    LoaderKel
  },
  data () {
    return {
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      meta: [],
      plaParaEliminar: '',
      modalConfirmacionEliminar: false,
      cargando: false
    }
  },
  watch: {
  },
  methods: {
    cargaInicial () {
      this.cargando = true
      apiPost({ s: 'metaMv' }, 'id=' + this.$route.params.id)
        .then((res) => {
          this.meta = res.data.meta
        }).then(() => { this.cargando = false })
    },
    eliminarPlanificacion () {
      this.cargando = true
      apiPost({ s: 'metaDrop' }, 'id=' + this.$route.params.id)
        .then((res) => {
          this.$router.go(-1)
        })
    }
  },
  mounted () {
    this.cargaInicial()
  }
}
</script>

<style lang="scss" scoped>
.table.t-meta {
  margin: 2.5rem auto 6rem;

  .contenido > [class*=col] {line-height: 1.3}

  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "768px";
  @include ul-table-responsive-at ($breakpointToChange);

  // Anchos específicos de columnas
  @media screen and (min-width: $breakpointToChange) {
    .acciones {width: 140px};
  }
}
</style>
